
































































































































































import router from "@/router";
import { defineComponent, reactive } from "@vue/composition-api";

import VuePhoneNumberInput from "vue-phone-number-input";
import { getPhoneInputOptions } from "@/helpers/phone-input";

export default defineComponent({
  name: "SignUp",
  components: {
    VuePhoneNumberInput
  },
  setup() {
    const state = reactive({
      contact: ""
    });

    function signUp() {
      router.push("/pages/add-listing");
    }

    const phoneInputOptions = getPhoneInputOptions();

    return {
      signUp,
      phoneInputOptions,
      state
    };
  }
});
